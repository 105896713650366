<template>
  <v-container>
    
    <v-row>

      <!-- FIGURES -->
      <v-col cols="6">
        <v-card outlined rounded="xl" class="py-2 d-flex flex-column">
          <span class="d-block text-center">參與人數</span>
          <div class="d-flex justify-center align-center">
            <v-icon>mdi-account-group</v-icon>
            <span class="ml-2 text-h6">{{ count.all | toFormattedNumber }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined rounded="xl" class="py-2 d-flex flex-column">
          <span class="d-block text-center">完成人數</span>
          <div class="d-flex justify-center align-center">
            <v-icon>mdi-account-group</v-icon>
            <span class="ml-2 text-h6">{{ count.completed | toFormattedNumber }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined rounded="xl" class="py-2 d-flex flex-column">
          <span class="d-block text-center">剩餘點數</span>
          <div class="d-flex justify-center align-center">
            <CustomIcon :src="require('@/assets/icons/point.svg')" :height="24"></CustomIcon>
            <span class="ml-2 text-h6">{{ budget.availablePoints | toFormattedNumber }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined rounded="xl" class="py-2 d-flex flex-column">
          <span class="d-block text-center">已發點數</span>
          <div class="d-flex justify-center align-center">
            <CustomIcon :src="require('@/assets/icons/point.svg')" :height="24"></CustomIcon>
            <span class="ml-2 text-h6">{{ budget.usedPoints | toFormattedNumber }}</span>
          </div>
        </v-card>
      </v-col>

      <!-- CHARTS -->
      <v-col cols="6">
        <v-card outlined rounded="xl" class="pt-2 pb-4 d-flex flex-column" max-height="220px">
          <span class="d-block text-center">活動預算</span>
          <Doughnut
            :chart-data="chart.budget.data" :chart-options="chart.budget.options">
          </Doughnut>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined rounded="xl" class="pt-2 pb-4 d-flex flex-column" max-height="220px">
          <span class="d-block text-center">活動人數</span>
          <Doughnut
            :chart-data="chart.participation.data" :chart-options="chart.participation.options">
          </Doughnut>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined rounded="xl" class="pt-2 pb-4 d-flex flex-column" max-height="220px">
          <span class="d-block text-center">會員類別</span>
          <Doughnut
            :chart-data="chart.citizenship.data" :chart-options="chart.citizenship.options">
          </Doughnut>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined rounded="xl" class="pt-2 pb-4 d-flex flex-column" max-height="220px">
          <span class="d-block text-center">參與性別</span>
          <Doughnut
            :chart-data="chart.gender.data" :chart-options="chart.gender.options">
          </Doughnut>
        </v-card>
      </v-col>

    </v-row>

    <!-- Participant List Query Settings -->
    <div class="mt-5 mb-3 d-flex">
      <v-text-field dense filled rounded hide-details clearable
        label="以電話搜尋" v-model="searchByPhone" @click:clear="onSearchByPhoneCleared">
      </v-text-field>
      <v-btn fab icon @click="search">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>

    <!-- List of Participants -->
    <SSRTable ref="list-participants" :no-data-text="'尚無參與會員記錄'"
      :data-url="fetchParticipantsUrl" :data-key="'participants'" :cols="cols">

      <div slot="member" slot-scope="{ item }">
        <div class="d-flex flex-column">
          <span>{{ item.name }}</span>
          <span class="text-caption grey--text">{{ item.phone }}</span>
        </div>
      </div>

      <div slot="time" slot-scope="{ item }">
        {{ item.createdAt | tsToDateString }}
      </div>

      <div slot="completeness" slot-scope="{ item }">
        <v-icon color="green" v-if="item.completeness == 'completed'">mdi-check</v-icon>
      </div>

    </SSRTable>

    <!-- A dummy spacing at the end of list FIXME: to avoid content overlapping -->
    <div style="height: 72px;">
    </div>

  </v-container>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy';
import { Chart, ArcElement, Legend, Color } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ArcElement);
Chart.register(Legend);
// Chart.register(Color);
Chart.register(ChartDataLabels);

import SSRTable from '@/components/SSRTable.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

const DEFAULT_CHART_OPTIONS = {
  responsive: true,
  plugins: {
    datalabels: {
      color: '#FFFFFF',
      font: { weight: 'bold' }
    },
    legend: {
      labels: { color: 'white' }
    }
  }
};

export default {
  components: {
    Doughnut,
    SSRTable
  },
  props: {
    campaignId: String
  },

  mounted() {
    this.fetchParticipantStats();
    this.fetchBudgetStats();
  },

  methods: {

    fetchParticipantStats() {
      SSORequest.get(
        `${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/campaigns/${this.campaignId}/participant-stats`
        + `?from=20220101&to=20301213`
      )
      .then(response => {
        //TODO: 轉換成圖表與數字
        this.count = response.data['count'];
        this.gender = response.data['demography']['gender'];
        this.citizenship = response.data['demography']['citizenship'];

        // 畫出相關圖表
        this.drawParticipationChart();
        this.drawCitizenshipChart();
        this.drawGenderChart();
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得參與統計資料！'
        });
      });
    },

    fetchBudgetStats() {
      SSORequest.get(
        `${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/campaigns/${this.campaignId}/budget-stats`
      )
      .then(response => {
        //TODO: 轉換成圖表與數字
        this.budget = response.data;

        // 畫出相關圖表
        this.drawBudgetChart();
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得預算資料！'
        });
      });
    },

    drawParticipationChart() {
      this.chart.participation.data = {
        labels: [ '未完成', '已完成' ],
        datasets: [{
          data: [ this.count.all - this.count.completed, this.count.completed ],
          backgroundColor: [ '#EF6079', '#F3CBC7' ],
          borderColor: 'transparent'
        }]
      };
    },

    drawBudgetChart() {
      this.chart.budget.data = {
        labels: [ '未發出', '已發出' ],
        datasets: [{
          data: [ this.budget.availablePoints, this.budget.usedPoints ],
          backgroundColor: [ '#EF6079', '#F3CBC7' ],
          borderColor: 'transparent'
        }]
      };
    },

    drawCitizenshipChart() {
      this.chart.citizenship.data = {
        labels: [ '市民', '訪客' ],
        datasets: [{
          data: [ this.citizenship.yes, this.citizenship.no ],
          backgroundColor: [ '#EF6079', '#F3CBC7' ],
          borderColor: 'transparent'
        }]
      };
    },
    drawGenderChart() {
      this.chart.gender.data = {
        labels: [ '女性', '男性' ],
        datasets: [{
          data: [ this.gender.f, this.gender.m ],
          backgroundColor: [ '#EF6079', '#F3CBC7' ],
          borderColor: 'transparent'
        }]
      };
    },

    search() {
      if (this.searchByPhone && this.searchByPhone.length)
        this.$refs['list-participants'].loadData();
    },
    onSearchByPhoneCleared() {
      this.searchByPhone = '';
      console.log('onSearchByPhoneCleared', this.searchByPhone, this.fetchParticipantsUrl);
      setTimeout(() => {//NOTE: Delay a tick so that the computed URL loads correctly.
        this.$refs['list-participants'].loadData();
      });
    }
  },

  data() {
    return {

      // Stats.
      count: { all: 0, completed: 0 },
      gender: { f: 0, m: 0},
      citizenship: { yes: 0, no: 0 },
      budget: { availablePoints: 0, usedPoints: 0 },

      chart: {
        participation: {
          data: { },
          options: DEFAULT_CHART_OPTIONS
        },
        budget: {
          data: { },
          options: DEFAULT_CHART_OPTIONS
        },
        citizenship: {
          data: { },
          options: DEFAULT_CHART_OPTIONS
        },
        gender: {
          data: { },
          options: DEFAULT_CHART_OPTIONS
        }
      },

      // List of participants.
      cols: [
        { value: 'member', text: '會員' },
        { value: 'time', text: '參與時間' },
        { value: 'completeness', text: '完成', align: 'center' },
      ],
      searchByPhone: '',


      // defChartOptions: {
      //   responsive: true,
      //   plugins: {
      //     legend: {
      //       display: true,
      //       position: 'bottom'
      //     }
      //   }
      // }
    };
  },

  computed: {
    fetchParticipantsUrl() {
      return `${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/campaigns/${this.campaignId}/participants?from=20220101&to=20301231`//FIXME: Literally all-time.
        + (this.searchByPhone && this.searchByPhone.length? '&search=phone:' + this.searchByPhone: '')
    },
  },

  filters: {
    toFormattedNumber(n) {
      return Number(n).toLocaleString();
    },
    tsToDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D h:mmA')
    }
  }
}
</script>

<style scoped>
.theme--dark.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme--dark.v-data-table {
  background-color: transparent;
}
</style>