import Vue from 'vue'
import Vuex from 'vuex'

import _ from 'underscore';
import axios from 'axios';
// axios.defaults.withCredentials = true;
import SSORequest from './sso-request';

import { firestore } from './firebase';
import {
  collection, doc, getDocs, setDoc,
  query, where, limit,
  onSnapshot,
  writeBatch,
  orderBy
} from 'firebase/firestore';
import moment from 'moment';

Vue.use(Vuex);

const MAX_BATCH_SIZE = 500;

export default new Vuex.Store({

  state: {
    merchantId: process.env.VUE_APP_MERCHANT_ID,

    user: {
      uuid: null,
      userId: null,//Usually has 'line.' prefix.
      displayName: null,
      email: null,
      phone: null,
      oid: null,
      memberId: null,
      picture: null
    },
    
    storeMap: { },
    campaigns: [ ], //FIXME: We probably shouldn't cache campaigns here.

    // Stateful variables
    // storesDataUpdatePending: true
    hasSignedIn: false,
    storesDataUpdateStatus: 'pending',

    // For notifications
    topicMap: {},
    notifications: { },
    notificationPopupEnabled: false,
    listener: null
    // notificationsUpdateStatus: 'pending'
  },

  getters: {
    user(state) {
      return state.user;
    },
    stores(state) {
      return _.values(state.storeMap);
    },
    storeMap(state) {
      return state.storeMap;
    },

    store(state) {
      return storeId => {
        return state.storeMap[ storeId ];
      }
    },

    storeRoleId(state) {
      return storeId => {
        return state.storeMap[ storeId ]['role_id'] || 2;
        /**
         * 1: Administrator
         * 2: Employee (default)
         * 3: Accountant
         */
      }
    },

    campaigns(state) {
      return state.campaigns;
    },

    // topicMap(state) {
    //   return state.topicMap;
    // }
    notifications(state) {
      return state.notifications;
      // return _.values(state.notifications);
    },
    unreadNotiCount(state) {
      return _.values(state.notifications)
        .filter(noti => noti.unread === true).length;
    }
  },

  mutations: {

    setUser(state, profile) {
      console.log(`[Vuex Store]<DEBUG> setUser: profile`, profile);
      let user = profile['user']['_json'];//FIXME: Why use this key?
      let memberId = user['oid'].split('/')[1];
      state.user = {
        uuid: profile['uuid'],
        userId: profile['userId'],
        displayName: profile['displayName'],
        oid: user['oid'],
        memberId,
        email: user['email'],
        phone: user['phoneNumber'],
        picture: user['picture']
      };
      state.hasSignedIn = true;
    },

    setStoreMap(state, _storeMap) {
      state.storeMap = _storeMap;
    },

    setPointServiceByStore(state, points, storeId) {
      let store = state.storeMap[ storeId ]
      if (!store) return;//FIXME: Should avoid this situation.
      store.points = points;
      Vue.set(state.storeMap, storeId, store);
    },
    setStoresDataUpdateStatus(state, status) {
      state.storesDataUpdateStatus = status;
    },

    /// Stamp Cards TODO:

    /// Notifications
    addNotification(state, message) {
      // state.notifications.push(message);
      Vue.set(state.notifications, message.refId, message);
    },
    clearNotifications(state) {
      state.notifications = [ ];
    },
    setTopicListener(state, { topicId, listener }) {
      if (!state.topicMap[ topicId ]) {
        state.topicMap[ topicId ] = { };
      }
      state.topicMap[ topicId ].listener = listener;
    },
    setNotificationPopupEnabled(state, enable) {
      state.notificationPopupEnabled = enable;
    },
    // setNotificationsUpdateStatus(state, status) {
    //   state.notificationsUpdateStatus = status;
    // }

    setHasSignedIn(state) {
      state.hasSignedIn = true;
    },
  },

  actions: {

    /// Stores related

    initStoresData({ state, commit, dispatch }) {
      const shouldInitFirestore = state.storesDataUpdateStatus == 'pending';

      if (!state.hasSignedIn) {
        console.warn(`[Vuex Store] initStoresData: stores-data requested but haven't signed in yet.`);
        return;
      } 
      if (state.storesDataUpdateStatus == 'done') {
        console.warn(`[Vuex Store] initStoresData: stores-data requested but it's indicated that no need to update for now.`);
        return;
      }
      if (state.storesDataUpdateStatus == 'updating') {
        console.warn(`[Vuex Store] initStoresData: stores-data requested but it's still updating.`);
        return;
      }
      console.warn(`[Vuex Store] initStoresData: stores-data requested.`);
      commit('setStoresDataUpdateStatus', 'updating');
      
      // Prepare cache map;
      let storeMap = { };

      // Firstly, obtain list of manageable stores. WARNING: API revision pending.
      return SSORequest.get(
        `${process.env.VUE_APP_TY_MANAGER_HOST}/stores?memberId=${state.user.memberId}`
      )
      .then(response => {

        // "response.data" should be an array of store-info.
        response.data.forEach(store => {
          storeMap[ store['id'] ] = store;
        });

        // Then, we ask for enabled point services of stores.

        return Promise.all([
          SSORequest.get(
            `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
            + `/members/${state.user.userId}/store-points`
          ),
          SSORequest.get(
            `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
            + `/members/${state.user.userId}/store-closing-balance`
          )
        ])

        // return axios.get(
        //   `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        //   + `/members/${state.user.userId}/store-points`
        // );
        // return SSORequest.get(
        //   `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        //   + `/members/${state.user.userId}/store-points`
        // );
        
      })
      .then(responses => {
        let [ response1, response2 ] = responses;
        console.log(`response1`, response2.data);
        console.log(`response2`, response2.data);

        // "response1.data" should be an array of point-services.
        response1.data.forEach(pointService => {
          let storeId = pointService['storeId'];
          if (!storeMap[ storeId ]) return;//FIXME: Should try to avoid this.
          if (!storeMap[ storeId ].points)
            storeMap[ storeId ].points = [ ];
          storeMap[ storeId ].points.push(pointService);
        });

        // "response2.data" should be an array of stores with their closing-balance amount.
        response2.data.forEach(store => {
          let storeId = store['storeId'];
          if (!storeMap[ storeId ]) return;//FIXME: Should try to avoid this.
          storeMap[ storeId ].closingBalance = store['closingBalance'] || 0;
        });
        
        commit('setStoreMap', storeMap);
        // console.log(`[Vuex Store] initStoresData: stores data obtained.`, JSON.stringify(storeMap));
        commit('setStoresDataUpdateStatus', 'done');

        //FIXME: Only do the following if this is the 1st run.
        if (shouldInitFirestore) {
          // Init Firestore listener for notification as well since the stores data are ready.
          dispatch('loadTopics');
          // Delay a bit and load message read records.
          setTimeout(() => {
            dispatch('loadReads');
          }, 3000);
        }

        return storeMap;
      })
      .catch(err => {
        console.error(`[Vuex Store] initStoresData: error`, err);
        throw err;
      });
    },

    fetchPointServicesByStoreId({ state, commit }, storeId) {
      return SSORequest.get(
        `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}/stores/${storeId}/points`
      )
      .then(response => {
        console.log(`[Vuex Store]<DEBUG> fetchPointServicesByStoreId: got point-service of store ${storeId}`, response.data);
        // commit('setPointServiceByStore', response.data, storeId);
        // commit('setStoresDataUpdatePending', true);
        return response.data;
      })
      .catch(err => {
        console.error(`[Vuex Store]<DEBUG> fetchPointServicesByStoreId: error`, err);
        throw err;
      });
    },

    /// Stamp Cards related

    fetchStampCards({ state, commit }) {
    //   return SSORequest.get(`${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
    //     + `/stores/${this.storeId}/stamp-cards`)
    //   .then(response => {

    //   })
    //   .catch(err => {
    //     console.error(`[Vuex Store]<DEBUG> fetchStampCards: error`, err);
    //     throw err;
    //   });
    },

    /// Campaigns related

    fetchCampaigns({ state, commit }) {
      return SSORequest.get(
        `${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${state.merchantId}`
        + `/campaigns?memberId=${state.user.memberId}`
      )
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
    },

    /// Notifications related

    loadTopics({ state, commit }) {
      commit('clearNotifications');
      commit('setNotificationPopupEnabled', false);
      // state.notificationPopupEnabled = false;
      
      for (const storeId in state.storeMap) {
        let store = state.storeMap[ storeId ];
        let topicId = `store-${storeId}`;

        let path = `notifications/${process.env.VUE_APP_MERCHANT_ID}`
          + `/topics/${topicId}/messages`;
        console.log(`[Vuex Store]<DEBUG> loadTopics: loading topic ${topicId} for store ${store.name}...`, path);
        const messagesRef = collection(
          firestore,
          path
        );

        // getDocs(messagesRef).then(qSnapshot => {
        //   qSnapshot.forEach(doc => {
        //     console.log(doc.id, '=>', doc.data());
        //   })
        // });

        // Register a data listener for this topic //FIXME: Only load messages in 1 week
        let q = query(
          messagesRef,
          where('ts', '>=', moment().add(-1, 'weeks').startOf('day').unix()),
          orderBy('ts', 'desc'),
          limit(100) //FIXME: To avoid performance hazard, we cap the loaded message count at 100.
        );

        if (state.topicMap[ topicId ] && state.topicMap[ topicId ].listener) {//NOTE: No need to re-init a listener for this topic.
          console.warn(`[Vuex Store] loadTopics: no need to re-init listener for topic ${topicId}.`);
          continue;
        }
        let listener = onSnapshot(q, snapshot => {
          // console.warn(`[Vuex Store] Listener of topic ${topicId} is triggered`, snapshot.size);
          snapshot.docChanges().forEach(change => {
            const msgDoc = change.doc;
            const data = msgDoc.data();

            if (change.type === 'added') {
              // console.warn(`[Vuex Store] Listener of topic ${topicId} finds a new doc added`, msgDoc.data());
              commit('addNotification', _.extend(data, {
                storeId,
                storeName: store.name,
                refId: msgDoc.id
              }));

              // Show notification!
              if (state.notificationPopupEnabled) {
                const dateTime = moment.unix(data.ts).format('YYYY/M/D h:mmA');
                Vue.notify({
                  group: 'noti-popup',
                  title: data.title,
                  text: `${data.text}<br>
                  特店：${store.name}<br>
                  <span style="color:grey">${dateTime}</span>
                  `
                });
              }
              
            }
            else if (change.type === 'modified') {
              console.warn(`[Vuex Store] Listener of topic ${topicId} finds a doc modified`, msgDoc.data());
              //TODO: ???
              commit('addNotification', _.extend(msgDoc.data(), {
                storeId,
                storeName: store.name,
                refId: msgDoc.id,
                modified: true
              }));
            }
            
          });
        });
        commit('setTopicListener', { topicId, listener });
      }

      // Delay a few seconds and enable notification popups (using Vue-Notifications~)
      setTimeout(() => {
        console.warn(`listeners`, state.topicMap);
        commit('setNotificationPopupEnabled', true);
      }, 5000);

    },
    loadReads({ state, commit }) {//NOTE: Obtain this member's read records in another branch of data.
      let path = `notifications/${process.env.VUE_APP_MERCHANT_ID}/recipients/${state.user.memberId}/readMessages`;
      const readMessagesRef = collection(
        firestore,
        path
      );
      console.log(`[Vuex Store]<DEBUG> loadReads: collection path is`, path);

      // getDocs(readMessagesRef).then(qSnapshots => {
      //   qSnapshots.forEach(doc => {
      //     console.log(doc.id, '=>', doc.data());
      //   })
      // })

      let q = query(readMessagesRef, where('readAt', '>=', moment().add(-1, 'weeks').startOf('day').unix()));
      let listener = onSnapshot(q, snapshot => {
        snapshot.docChanges().forEach(change => {
          let doc = change.doc;
          let record = doc.data();
          // console.log(`[Vuex Store]<DEBUG> loadReads: on-snapshot`, record, change.type);
          let message = state.notifications[ record.messageId ];
          if (!message)
            return;
          if (change.type === 'added') {//NOTE: Newly obtained message read records.
            message.unread = false;
            commit('addNotification', message);
          }
          // else if (change.type === 'modified') {//Recently updated read status.
          //   message.unread = false;
          //   commit('addNotification', message);
          // }
        });
      });
    },
    markRead({ state, commit }, { topicId, refId }) {
      // let path = `notifications/${process.env.VUE_APP_MERCHANT_ID}`
      //   + `/recipients/${topicId}/messages/${refId}`;console.log(path);
      // let msgRef = doc(firestore, path);
      // setDoc(msgRef, { unread: false }, { merge: true });
      SSORequest.post(
        `${process.env.VUE_APP_TY_AIR3_HOST}/v3/merchants/${process.env.VUE_APP_MERCHANT_ID}/firestore-mark-read`,
        { topicId, messageId: refId, memberId: state.user.memberId }
      )
      .then(response => {

      })
      .catch(err => {

      });
    },
    markAllRead({ state, commit }) {
      
      let batch;
      let batchSize = 0;
      for (const refId in state.notifications) {
        let noti = state.notifications[ refId ];
        if (!noti.unread)
          continue;

        // Renew a batch if required.
        if (!batchSize) {
          batch = writeBatch(firestore);
        }

        // Put set-doc into the batch.
        let topicId = `store-${noti.storeId}`;
        let path = `notifications/${process.env.VUE_APP_MERCHANT_ID}`
          + `/recipients/${topicId}/messages/${refId}`;
        let msgRef = doc(firestore, path);
        batch.set(msgRef, { unread: false }, { merge: true });
        batchSize++;

        // If the batch if maximally loaded, fire away.
        if (batchSize >= MAX_BATCH_SIZE) {
          batch.commit().then(() => {
            console.warn(`[Vuex Store] markAllRead: a batch of ${batchSize} successfully committed.`);
          })
          .catch(err => {
            console.error(`[Vuex Store] markAllRead: a batch of ${batchSize} failed to commit.`, err)
          });
          batchSize = 0;
        }
      }
      if (batchSize > 0) {
        batch.commit().then(() => {
          console.warn(`[Vuex Store] markAllRead: a batch of ${batchSize} successfully committed.`);
        })
        .catch(err => {
          console.error(`[Vuex Store] markAllRead: a batch of ${batchSize} failed to commit.`, err)
        });
      }

    },
    detachFirestoreListeners({ state }) {
      for (const topicId in state.topicMap) {
        if (state.topicMap[ topicId ].listener)
        state.topicMap[ topicId ].listener();
      }
    },

    /// Auth methods

    signIn({ state, commit }) {

      return new Promise((resolve, reject) => {
        window.qcsso
        .init({
          appId: process.env.VUE_APP_QCSSO_APP_ID
        })
        .then(() => {
          if (!window.qcsso.isLoggedIn()) {
            console.warn(`[Vuex Store] signIn: QCSSO said you're not logged in, will redirect`);
            window.qcsso.login({ redirectUrl: `${process.env.VUE_APP_WEB_HOST}/admin` });//WARNING: This should redirect you away.
            // resolve();
          }
          else {
            let ctx = window.qcsso.getContext();
            console.log(`QCSSO: logged in context`, ctx);

            window.qcsso.getProfile()
            .then(profile => {
              console.log(`[Vuex Store]<DEBUG> signIn: QCSSO gave user profile`, profile);
              console.warn(`[Vuex Store]<DEBUG> signIn: QCSSO asks for access token`, window.qcsso.getAccessToken());
              commit('setUser', profile);
              resolve();
            })
            .catch(e => {
              // alert('失敗 ' + e.stack);
              reject('Failed to sign-in' + e.stack);
            });

            // alert('access ', $qcsso.getAccessToken());
            // this.$router.push('/admin');
          }

        });
      });

      
    },

    signOut({ state, commit }) {
      console.warn(`Will signout`);
      window.qcsso.logout({
        logoutRedirectUri: `${process.env.VUE_APP_WEB_HOST}/signin`
      })
      .then(response => {
        console.log(`[Vuex Store] signOut done`);
      })
      .catch((e) => {
        console.error(`[Vuex Store]<ERROR> signOut failed`, e);
      });
    }
    
  }
});