<template>
  <v-container>

    <!-- Stamp Card basic info -->
    <v-card outlined rounded="xl" class="mt-2">

      <!-- Stamp Card Banner -->
      <v-img :src="info.bannerImageUrl"  min-width="100%"></v-img>

      <v-row align="center" class="mt-2">

        <v-col cols="8">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 mb-2">
                {{ info.stampCardName }}
              </v-list-item-title>
              <span class="mb-2 text-caption white--text font-weight-bold">
                特店 {{ info.storeName }}
              </span>
              <v-list-item-subtitle>
                集章 {{ info.collectStartTime | toShortDateString }} - {{ info.collectEndTime | toShortDateString }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                兌換 {{ info.redeemStartTime | toShortDateString }} - {{ info.redeemEndTime | toShortDateString }}
              </v-list-item-subtitle>
              <span class="mt-2 text-subtitle-2 white--text font-weight-bold">
                集章數上限 x {{ info.stampCardMaxStamps }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="4" class="my-2 d-flex justify-center align-center" style="position:relative;">
          <v-btn x-small fab absolute bottom right color="primary" style="bottom:4px;"
            @click="showUpdateStampImageDialog">
            <v-icon>mdi-image-edit-outline</v-icon>
          </v-btn>
          <v-img max-width="84" max-height="84" class="rounded-lg"
            :src="info.stampImageUrl">
          </v-img>
        </v-col>

        <!-- Edit stamp card info button -->
        <v-col cols="12" class="py-0 d-flex justify-end">
          <v-btn text color="primary"
            @click="goToStampCardEditPage">
            <v-icon left>mdi-pencil</v-icon>
            編輯基本資訊
          </v-btn>
        </v-col>

        <v-col cols="12" class="pt-0">
          <v-divider></v-divider>

          <!-- <div class="my-2 text-h6 text-center">集章獎項</div> -->
        </v-col>

        <v-col cols="12">
          <v-card outlined rounded="xl" class="mx-2 mb-2"
            v-for="reward in rewards" :key="reward.rewardId">
            <v-row align="center">
              <v-col cols="8">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 mb-2">
                      {{ reward.rewardName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ reward.rewardDesc }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span class="text-subtitle-1 font-weight-bold" style="color:#EF6079">
                        兌換章數 x {{ reward.rewardStampsRequirement }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="4" class="my-2 d-flex flex-column justify-center align-center">
                <v-img max-width="64" max-height="64" class="rounded-lg"
                  :src="reward.rewardImageUrl">
                </v-img>
                <div class="pt-2 d-flex">
                  <v-btn small icon
                    @click="showUpdateRewardDialog(reward)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn small icon color="red" class="ml-2"
                    @click="deleteReward(reward.rewardId)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
              
            </v-row>
          </v-card>

          <!-- Add-reward button -->
          <div class="mt-2 d-flex justify-center">
            <v-btn rounded depressed color="primary"
              @click="showCreateRewardDialog">
              <v-icon>mdi-plus</v-icon>新增獎項
            </v-btn>
          </div>
          
        </v-col>

        <!-- Stamp Card Descriptions -->
        <v-col cols="12">
          <v-divider></v-divider>
          <div class="px-4 py-2">
            <div class="my-2 text-h6 text-center">優惠說明</div>
            <span v-html="desc"></span>
          </div>
          <div class="px-4 py-2">
            <div class="my-2 text-h6 text-center">注意事項</div>
            <span v-html="notices"></span>
          </div>
        </v-col>

      </v-row>
    </v-card>


    <!-- Dialog - create reward -->
    <v-dialog v-model="dialog.reward.show">
      <v-card>
        <v-card-title>
          <span v-if="dialog.reward.mode == 'create'">新增獎項</span>
          <span v-else>編輯獎項</span>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <template>
            <CustomIcon :src="dialog.reward.rewardImageSrc" v-if="!dialog.reward.previewRewardImageSrc"
              :height="72" class="mx-auto my-4 rounded-lg">
            </CustomIcon>
            <CustomIcon :src="dialog.reward.previewRewardImageSrc" v-else
              :height="72" class="mx-auto my-4 rounded-lg">
            </CustomIcon>
          </template>
          <div class="my-2"></div>

          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form>

              <validation-provider v-slot="{ errors }" name="Reward Image" rules="size:64">
                <v-file-input dense rounded filled v-model="dialog.reward.model.rewardImage"
                  prepend-icon=""  prepend-inner-icon="mdi-image-plus"
                  show-size counter accept="image/png, image/jpeg"
                  truncate-length="15" @change="previewRewardImage"
                  :error-messages="errors">
                </v-file-input>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="Name" rules="required|min:2">
                <v-text-field dense filled rounded class="align-right-input"
                  v-model="dialog.reward.model.name" :error-messages="errors">
                  <template v-slot:prepend-inner>
                    <span class="text-subtitle-1 font-weight-bold">名稱</span>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="Desc" rules="required|min:2">
                <v-text-field dense filled rounded class="align-right-input"
                  v-model="dialog.reward.model.desc" :error-messages="errors">
                  <template v-slot:prepend-inner>
                    <span class="text-subtitle-1 font-weight-bold">描述</span>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="Requirement" rules="required|numeric">
                <v-text-field dense filled rounded class="align-right-input" type="number"
                  v-model="dialog.reward.model.requirement" :error-messages="errors">
                  <template v-slot:prepend-inner>
                    <span class="text-subtitle-1 font-weight-bold">兌換章數</span>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-btn rounded color="secondary" class="flex-grow-1"
            @click="dialog.reward.show = false">
            取消
          </v-btn>
          <v-btn rounded color="primary" class="flex-grow-1"
            :disabled="dialog.reward.isProcessing"
            :loading="dialog.reward.isProcessing"
            @click="submitReward">
            {{ dialog.reward.mode == 'create'? '新增': '更新' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - create reward -->
    <v-dialog v-model="dialog.stampImage.show">
      <v-card>
        <v-card-title>
          <span>上傳集章圖案</span>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <template>
            <CustomIcon :src="dialog.stampImage.data.stampImageUrl" v-if="!dialog.stampImage.previewStampImageSrc"
              :height="64" class="mx-auto my-4 rounded-lg">
            </CustomIcon>
            <CustomIcon :src="dialog.stampImage.previewStampImageSrc" v-else
              :height="64" class="mx-auto my-4 rounded-lg">
            </CustomIcon>
          </template>
          <div class="my-2"></div>

          <validation-observer ref="observer-stamp-image" v-slot="{ invalid }">
            <validation-provider v-slot="{ errors }" name="Stamp Image" rules="size:10">
              <v-file-input dense rounded filled v-model="dialog.stampImage.model.stampImage"
                prepend-icon=""  prepend-inner-icon="mdi-image-plus"
                show-size counter accept="image/png, image/jpeg"
                truncate-length="15" @change="previewStampImage"
                :error-messages="errors">
              </v-file-input>
            </validation-provider>
          </validation-observer>
        </v-card-text>

        <v-card-actions class="d-flex">
          <v-btn rounded color="secondary" class="flex-grow-1"
            @click="dialog.stampImage.show = false">
            取消
          </v-btn>
          <v-btn rounded color="primary" class="flex-grow-1"
            :disabled="!dialog.stampImage.model.stampImage || dialog.stampImage.isProcessing"
            :loading="dialog.stampImage.isProcessing"
            @click="uploadStampImage">
            確定上傳
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import { required, min, max, numeric, size } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: `此項目是必填的！`
});
extend('min', {
  ...min,
  message: `長度最少應為{length}字元`
});
extend('max', {
  ...max,
  message: `長度不得超過{length}字元`
});
extend('numeric', {
  ...numeric,
  message: `格式不正確`
});
extend('size', {
  ...size,
  message: `圖檔大小不得超過{size}kb`
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    storeId: String,
    stampCardId: String
  },

  mounted() {
    this.fetchStampCardInfo();
  },

  methods: {

    fetchStampCardInfo() {
      return SSORequest.get(`${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}`)
      .then(response => {
        this.info = response.data;

        // Also fetch the list of rewards of this stamp card
        return SSORequest.get(`${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}/rewards`);
      })
      .then(response => {
        this.rewards = response.data;
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得集章卡資訊！'
        });
      });
    },

    goToStampCardEditPage() {
      this.$router.push(`edit`);
    },

    showCreateRewardDialog() {
      this.dialog.reward.mode = 'create';
      this.dialog.reward.rewardId = null;
      this.dialog.reward.model = {
        name: '', desc: '', requirement: 1
      };
      this.dialog.reward.rewardImageSrc = require('@/assets/icons/reward.png'),
      this.dialog.reward.show = true;
    },
    showUpdateRewardDialog(reward) {
      this.dialog.reward.mode = 'update';
      this.dialog.reward.rewardId = reward.rewardId;
      this.dialog.reward.model = {
        name: reward.rewardName,
        desc: reward.rewardDesc,
        requirement: reward.rewardStampsRequirement,
      };
      this.dialog.reward.rewardImageSrc = reward.rewardImageUrl;
      this.dialog.reward.show = true;
    },
    submitReward() {
      this.$refs['observer'].validate()
      .then(result => {console.log(result);
        if (!result) {
          this.$notify({
            type: 'error', text: '請檢查表單是否完整。'
          });
          return;
        }

        this.dialog.reward.isProcessing = true;

        let url = `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
          + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}/rewards`;
        if (this.dialog.reward.mode == 'update')
          url += `/${this.dialog.reward.rewardId}`;
        let method = this.dialog.reward.mode == 'update'? 'put': 'post';

        let formData = new FormData();
        formData.append('name', this.dialog.reward.model.name);
        formData.append('desc', this.dialog.reward.model.desc);
        formData.append('requirement', this.dialog.reward.model.requirement);

        // 視情況將圖檔加入form-data
        if (this.dialog.reward.mode == 'create' && !this.dialog.reward.previewRewardImageSrc)
          formData.append('imageUrl', 'https://storage.googleapis.com/stamp-images-dev/default-assets/default-reward-image.png');//FIXME: Hard-coded default image URL.
        else if (this.dialog.reward.mode == 'create')
          formData.append('rewardImage', this.dialog.reward.model.rewardImage);
        else if (this.dialog.reward.mode == 'update' && this.dialog.reward.previewRewardImageSrc)
          formData.append('rewardImage', this.dialog.reward.model.rewardImage);

        return SSORequest({
          method, url,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        })
        .then(response => {
          this.dialog.reward.show = false;
          this.$notify({
            type: 'success',
            text: {
              create: `獎項：${this.dialog.reward.model.name} 新增成功！`,
              update: `獎項：${this.dialog.reward.model.name} 更新成功！`
            }[ this.dialog.reward.mode ]
          });
        })
        .catch(err => {
          console.error(`[STAMP-CARD-INFO] submitReward: request submission error`, err);
          this.$notify({
            type: 'error',
            text: {
              create: `發生錯誤，無法新增兌換獎項。`,
              update: `發生錯誤，無法更新兌換獎項。`
            }[ this.dialog.reward.mode ]
          });
        })
        .finally(() => {
          this.fetchStampCardInfo();//This forces a page data refresh.
          this.dialog.reward.isProcessing = false;
        });

      });
    },

    showUpdateStampImageDialog() {
      this.dialog.stampImage.data = { stampImageUrl: this.info.stampImageUrl };
      this.dialog.stampImage.model = { stampImage: null };
      this.dialog.stampImage.show = true;
    },
    uploadStampImage() {
      this.$refs['observer-stamp-image'].validate()
      .then(result => {
        if (!result) {
          this.$notify({
            type: 'error',
            text: '請檢查圖檔是否符合限制。'
          });
          return;
        }

        this.dialog.stampImage.isProcessing = true;
        let formData = new FormData();
        formData.append('stampImage', this.dialog.stampImage.model.stampImage);
        SSORequest({
          method: 'put',
          url: `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
            + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}/stamp-image`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        })
        .then(response => {
          this.$notify({
            type: 'success',
            text: `集章圖案更新成功！`
          });
          this.fetchStampCardInfo();
          this.dialog.stampImage.show = false;
        })
        .catch(err => {
          this.$notify({
            type: 'error',
            text: `集章圖案更新失敗！`
          });
        })
        .finally(() => {
          this.dialog.stampImage.isProcessing = false;
        });

      });
      
    },

    // updateReward(reward) {

    // },

    deleteReward(rewardId) {
      this.$swal({
        background: '#424242',
        color: '#ffffff',
        html: '<span style="color:white">確定要刪除此獎項嗎？</span>',
        icon: 'warning',
        confirmButtonText: '確認刪除',
        showCloseButton: true
      })
      .then(result => {
        if (!result.isConfirmed) return;

        SSORequest.delete(
          `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
          + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}`
          + `/rewards/${rewardId}`,
        )
        .then(response => {
          this.fetchStampCardInfo();//This forces a page data refresh.
          this.$notify({
            type: 'warning', text: `已刪除獎項。`
          });
        })
        .catch(err => {
          this.$notify({
            type: 'error', text: `無法刪除獎項！`
          });
        });
      });
    },

    previewRewardImage(file) {
      console.log('previewRewardImage', file);
      this.dialog.reward.previewRewardImageSrc = window.URL.createObjectURL(file);
    },
    previewStampImage(file) {
      console.log('previewStampImage', file);
      this.dialog.stampImage.previewStampImageSrc = window.URL.createObjectURL(file);
    }

  },

  data() {
    return {
      info: { },
      rewards: [ ],

      dialog: {
        reward: {
          show: false,
          mode: 'create',
          isProcessing: false,
          rewardId: null,
          model: {
            name: '', desc: '', requirement: 1
          },
          rewardImageSrc: null,
          previewRewardImageSrc: null
        },
        stampImage: {
          show: false,
          isProcessing: false,
          data: { },//Hold current data
          model: {
            stampImage: null
          },
          previewStampImageSrc: null
        }
      }
    };
  },

  computed: {
    desc() {
      if (!this.info.stampCardDesc)
        return '';
      if (!Array.isArray(this.info.stampCardDesc))
        return this.info.stampCardDesc;
      let ret = this.info.stampCardDesc.find(desc => desc['title'] === '優惠說明');
      return ret['text'];
    },
    notices() {
      if (!this.info.stampCardDesc)
        return '';
      if (!Array.isArray(this.info.stampCardDesc))
        return '';
      let ret = this.info.stampCardDesc.find(desc => desc['title'] === '注意事項');
      return ret['text'];
    }
  },

  filters: {
    toShortDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D');
    }
  }
}
</script>

<style scoped>
.container .theme--dark.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}

.align-right-input >>> input {
  text-align: right;
}

.v-list {
  background-color: transparent;
}
</style>